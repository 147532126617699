import React from "react"
import styled from "styled-components"

import Title from "../atoms/title"
import Box from "../atoms/box"

import Container from "../molecules/container"
import Header from "../organisms/header"
import Footer from "../organisms/footer"
import Divider from '../atoms/divider'

import useSticky from '../hooks/Sticky'

import "../layout.css"

const StyledMain = styled.main`
    text-align: center;
    max-width: 960px;
    margin: 0 auto;

    @media only screen and (min-width: 600px) {
        padding-bottom: 96px;
    }
`;

const InfoPage = ({title, children}) => {
    const { isSticky, element } = useSticky()

    return (
        <>
            <Header sticky={isSticky}>
                <Box>
                    <Divider />
                    <Title level={1} color="#fff" element={element}>{title}</Title>
                </Box>
            </Header>
            <Container>
                <StyledMain>
                    {children}
                </StyledMain>
            </Container>
            <Footer />
        </>
    )
}

export default InfoPage