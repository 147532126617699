import React from "react"
import Img from "gatsby-image"

const ImagePeople = ({image, alt}) => {
  return(
    <div style={{ width: '200px', display: 'inline-block', maxWidth: '200px', margin: 'auto' }}>
        <Img fluid={image} alt={alt} style={{borderRadius: '8px'}} />
    </div>
  )
}

export default ImagePeople
