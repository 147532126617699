import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import InfoPage from "../components/templates/infopage"

import SEO from "../components/atoms/seo"
import Title from "../components/atoms/title"
import Divider from '../components/atoms/divider'
import Box from "../components/atoms/box"
import GridItem from "../components/atoms/griditem"
import ImagePeople from "../components/atoms/images/image-people"
import Grid from "../components/molecules/grid"
import Line from "../components/atoms/line"

const ContactPage = () => {
    const data = useStaticQuery(graphql`
        query AboutData {
            ismail: file(relativePath: { eq: "people/IsmailVali.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            alastair: file(relativePath: { eq: "people/AlastairGraham.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            bret: file(relativePath: { eq: "people/BretSperling.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            david: file(relativePath: { eq: "people/DavidPedley.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            elliott: file(relativePath: { eq: "people/ElliottVerrreault.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            eca: file(relativePath: { eq: "press/ECA.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            gge: file(relativePath: { eq: "press/GGE.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            gambling: file(relativePath: { eq: "press/gamblingnews.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
        }
    `)

    const title = 'ABOUT US';
    const leadText = 'Get in touch with Yield Sec to discuss how we can help your market block black market threats to taxation yield and player protection.';

    return (
    <InfoPage title={title} leadText={leadText}>
        <SEO 
            title={title}
            description={leadText}
            pathname='/about'
            keywords="atropos, atropos intelligence, ismail vali, ismail vali ceo, ismail vali yield sec, yield sec, yieldsec, ismail vali atropos intelligence"
        />

        <Divider />
        <Box>
            <Title level={2}>
            About us
            </Title>
            <Divider />
            <p style={{textAlign: 'left'}}>
            Across various high-value consumer marketplaces, we build and operate actionable intelligence platforms.
            Our marketplace optimization and protection products serve many clients, delivering a single 
            source of truth that enables valuable decision-making, operations and enforcement.
            Our mission is to facilitate fair, safe and functioning ecosystems that do not fund crime - they fund our commerce and communities.
            </p>

            <p style={{textAlign: 'left'}}>
            Our senior team is comprised of leaders from betting and gaming, entertainment, data and analytics, advertising, law and professional services.
            </p>
        </Box>

        <Box style={{padding: '2rem'}}>
            <Grid>
                <GridItem size='15%'>
                    <ImagePeople image={data.ismail.childImageSharp.fluid} alt='ISMAIL VALI - FOUNDER & CEO, YIELD SEC' />
                </GridItem>
                <GridItem size='70%'>
                    <Title level={4} position="left">ISMAIL VALI - FOUNDER & CEO</Title>
                    <p style={{textAlign: 'left'}}>
                    A leading gaming industry executive and marketing consultant for over 20 years with an academic background in Law, 
                    as a graduate of Brasenose College, Oxford University. Ismail combined these two defining experiences 
                    allowing him to lead the team that innovated the Yield Sec platform and ecosystem approach to serve 
                    all legal stakeholders and help them benefit from the removal of crime across the gambling marketplace.
                    </p>
                </GridItem>
            </Grid>
        </Box>
        
        <Box style={{padding: '2rem'}}>
            <Grid>
                <GridItem size='15%'>
                    <ImagePeople image={data.bret.childImageSharp.fluid} alt='BRET SPERLING - CFO, YIELD SEC' />
                </GridItem>
                <GridItem size='70%'>
                    <Title level={4} position="left">BRET SPERLING - CFO</Title>
                    <p style={{textAlign: 'left'}}>
                    A seasoned financial professional and founder of Sperling Advisory LLC, 
                    a consultancy providing start-up and mid-market companies with leadership across M&A,
                    cap raises and rapid growth.  Prior to starting Sperling Advisory, Bret was a Principal at a large regional CPA firm.
                    </p>
                </GridItem>
            </Grid>
        </Box>
        
        <Box style={{padding: '2rem'}}>
            <Grid>
                <GridItem size='15%'>
                    <ImagePeople image={data.alastair.childImageSharp.fluid} alt='ALASTAIR GRAHAM - CCO, YIELD SEC' />
                </GridItem>
                <GridItem size='70%'>
                    <Title level={4} position="left">ALASTAIR GRAHAM - CCO</Title>
                    <p style={{textAlign: 'left'}}>
                    Alastair is an early stage investor and senior creative director and producer
                    with experience across advertising, production, streaming and broadcast. 
                    He has worked across documentaries, entertainment and advertising with diverse 
                    projects for partners including Amazon, BBC, British Airways, Coca-Cola, 
                    Honda and numerous betting, gaming and lottery brands.
                    </p>
                </GridItem>
            </Grid>
        </Box>
        
        <Box style={{padding: '2rem'}}>
            <Grid>
                <GridItem size='15%'>
                    <ImagePeople image={data.david.childImageSharp.fluid} alt='DAVID PEDLEY - CLO, YIELD SEC' />
                </GridItem>
                <GridItem size='70%'>
                    <Title level={4} position="left">DAVID PEDLEY - CLO</Title>
                    <p style={{textAlign: 'left'}}>
                    As an attorney, David has been involved in securities and M&A transactions 
                    valued at over $1 billion. David has represented start-ups, tech companies, 
                    venture capital and debt funds, merchant banks, and one of the world´s largest hedge funds. 
                    In legal practice, David´s extensive experience covers Education, Marketing, Medical & Tech clients.
                    </p>
                </GridItem>
            </Grid>
        </Box>
        
        <Box style={{padding: '2rem'}}>
            <Grid>
                <GridItem size='15%'>
                    <ImagePeople image={data.elliott.childImageSharp.fluid} alt='ELLIOTT VERREAULT - DATA & TECHNOLOGY PARTNER, YIELD SEC' />
                </GridItem>
                <GridItem size='70%'>
                    <Title level={4} position="left">ELLIOTT VERREAULT - DATA & TECHNOLOGY PARTNER</Title>
                    <p style={{textAlign: 'left'}}>
                    Expert in data collection, management and analysis for national security agencies.
                    Founder and CEO of software company AKTEK (Atropos Intelligence platform partner) with 
                    experience serving US/UK/EU govt and their work countering piracy, extremism and terrorism worldwide.
                    </p>
                </GridItem>
            </Grid>
        </Box>

        <Divider />
        <Line />
        <Divider />
        <Divider />

        <Title level={2}>
            Press
        </Title>
        <Box style={{padding: '2rem'}}>
            <Grid>
                <GridItem size='15%'>
                    <div style={{ width: '150px', display: 'inline-block', maxWidth: '150px', margin: 'auto' }}>
                        <Img fluid={data.gambling.childImageSharp.fluid} alt='Will AI and Data revolutionize iGaming and Sports Betting? YIELD SEC' />
                    </div>
                </GridItem>
                <GridItem size='70%'>
                    <Title level={4} position="left">
                        Will AI and Data revolutionize iGaming and Sports Betting?
                    </Title>
                    <Title level={5} position="left">
                        7th October 2022
                    </Title>
                    <Link to="https://www.gamblingnews.com/news/can-ai-and-data-revolutionise-igaming-and-sports-betting/" target="_blank" rel="noopener noreferrer" style={{float: 'left'}}>
                        Read more
                    </Link>
                </GridItem>
            </Grid>
        </Box>
        <Box style={{padding: '2rem'}}>
            <Grid>
                <GridItem size='15%'>
                    <div style={{ width: '150px', display: 'inline-block', maxWidth: '150px', margin: 'auto' }}>
                        <Img fluid={data.gge.childImageSharp.fluid} alt='Yield Sec CEO Ismail Vali to Deliver Keynote Speech at G2E 2022 in Las Vegas, YIELD SEC' />
                    </div>
                </GridItem>
                <GridItem size='70%'>
                    <Title level={4} position="left">
                        Yield Sec CEO Ismail Vali to Deliver Keynote Speech at G2E 2022 in Las Vegas
                    </Title>
                    <Title level={5} position="left">
                        7th October 2022
                    </Title>
                    <Link to="https://igamingfuture.com/yield-sec-ceo-ismail-vali-to-deliver-keynote-speech-at-g2e-las-vegas/#:~:text=Ismail%20Vali%2C%20founder%20and%20CEO,forthcoming%20Global%20Gaming%20G2E%20Expo" target="_blank" rel="noopener noreferrer" style={{float: 'left'}}>
                        Read article
                    </Link>
                    <br />
                    <Link to="https://www.globalgamingexpo.com/en-us/education/g2e-education-schedule/session-details.2900.164018.deep-dive-illegal-operators--the-marketplace.html" target="_blank" rel="noopener noreferrer" style={{float: 'left'}}>
                        Session info
                    </Link>
                </GridItem>
            </Grid>
        </Box>
        <Box style={{padding: '2rem'}}>
            <Grid>
                <GridItem size='15%'>
                    <div style={{ width: '150px', display: 'inline-block', maxWidth: '150px', margin: 'auto' }}>
                        <Img fluid={data.eca.childImageSharp.fluid} alt='Yield Sec CEO invited to address the European Casino Association (ECA) Industry Forum, ATROPOS' />
                    </div>
                </GridItem>
                <GridItem size='70%'>
                    <Title level={4} position="left">
                        Yield Sec CEO invited to address the European Casino Association (ECA) Industry Forum
                    </Title>
                    <Title level={5} position="left">
                        26th September 2022
                    </Title>
                    <Link to="https://igamingfuture.com/yield-sec-ceo-ismail-vali-to-address-european-casino-association-eca-industry-forum/" target="_blank" style={{float: 'left'}}>
                        Read more
                    </Link>
                </GridItem>
            </Grid>
        </Box>
    </InfoPage>
    )
}

export default ContactPage