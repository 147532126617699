import React from "react";
import styled from "styled-components"

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 960px;
    margin: 0 auto;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

const Grid = ({children, ...props}) => {
    return (
        <StyledContainer {...props}>
            {children}
        </StyledContainer>
    )
}

export default Grid